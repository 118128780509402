import { useEffect, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import SearchIcon from '@ancon/wildcat-ui/shared/icons/i-search.svg'

import BodyText from '../../../app/components/BodyText'
import HeadingText from '../../../app/components/HeadingText'
import Card from '../../../layout/components/card/Card'
import Input from '../../../app/components/Input'
import useDeviceSize from '../../../app/hooks/useDeviceSize'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { outletsSetVisibleOutletSearchModal } from '../../store/outletsSlice'
import { OutletSearchModalType } from '../../types'
import useAppStore from '../../../../store/hooks/useAppStore'
import { outletsVisibleOutletSearchModalSelector } from '../../store/outletsSelectors'
import { AppTestIds } from '../../../app/constants'

import styles from './OutletSearchCard.module.scss'
import OutletSearchResults from './OutletSearchResultsOverlay'

export default function OutletSearchCard() {
  const { t } = useTranslation('home')

  const store = useAppStore()
  const dispatch = useAppDispatch()
  const searchResultsRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)
  const { isMobile } = useDeviceSize()
  const [isFocused, setFocused] = useState(false)
  const [query, setQuery] = useState('')

  function handleSearchTermChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFocused(true)
    setQuery(event.target.value)
  }

  function handleClearSearch() {
    setFocused(false)
    setQuery('')
  }

  function handleInputFocus() {
    if (isMobile) {
      dispatch(outletsSetVisibleOutletSearchModal(OutletSearchModalType.All))
    } else {
      setFocused(true)
    }
  }

  // Listen outside click events
  useEffect(() => {
    function handleMouseDown(e: MouseEvent) {
      const searchResultsClicked =
        searchResultsRef.current &&
        searchResultsRef.current.contains(e.target as Node)
      const searchInputClicked =
        searchInputRef.current &&
        searchInputRef.current.contains(e.target as Node)

      if (!isMobile && !searchInputClicked && !searchResultsClicked) {
        setFocused(false)
      }
    }

    document.addEventListener('mousedown', handleMouseDown)

    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [isMobile, isFocused])

  // Listen device size changes for mobile search modal
  useEffect(() => {
    const outletSearchModalType = outletsVisibleOutletSearchModalSelector(
      store.getState(),
    )
    const isOutletSearchModalOpen =
      outletSearchModalType !== OutletSearchModalType.None

    if (!isOutletSearchModalOpen && isMobile && isFocused) {
      dispatch(outletsSetVisibleOutletSearchModal(OutletSearchModalType.All))
      setFocused(false)
    }
    if (isOutletSearchModalOpen && !isMobile) {
      dispatch(outletsSetVisibleOutletSearchModal(OutletSearchModalType.None))
    }
  }, [isMobile, store, isFocused, dispatch])

  return (
    <Card className={styles.container}>
      <HeadingText as="h1" className={styles.title}>
        {t('title')}
      </HeadingText>
      <BodyText className={styles.subtitle} as="p" color="body-1">
        {t('subtitle')}
      </BodyText>
      <Input
        ref={searchInputRef}
        leftAccessory={<SearchIcon />}
        id={AppTestIds.Home.OutletSearchInput}
        containerClassName={styles.searchInput}
        highlighted
        placeholder={t('outletSearchPlaceholder')}
        dropdownView={
          isFocused && !isMobile ? (
            <OutletSearchResults ref={searchResultsRef} query={query} />
          ) : undefined
        }
        clearable={!!query}
        value={query}
        onChange={handleSearchTermChange}
        rightAccessoryOnClick={handleClearSearch}
        onFocus={handleInputFocus}
      />
    </Card>
  )
}
